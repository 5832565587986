const appSlug = 'balfin';

const settings = {
  supportedLocales: ['en', 'sq'],
  defaultLocale: 'en',

  domain: 'https://balfin-cms.azurewebsites.net',

  squidexClient: {
    id: `${appSlug}:default`,
    secret: '5i5mqfxt9wxjsaqq6f6beor1ylxkpyknxgxpu6hvgg8x',
  },

  graphqlApi: `/api/content/${appSlug}/graphql`,
  contentApi: `/api/content/${appSlug}`,
  assetsApi: `/api/assets/${appSlug}`,

  googleMapsApiKey: {
    dev: 'AIzaSyC3vAoigGRjndPnDmMDtN2KyDYvQdwiVLI',
    prod: 'AIzaSyDn74SSwCprURSnJps9Xl6PgXADubY3xLA',
  },
};

export default settings;
