import React, {useEffect} from 'react';
import {useLocation, useNavigationType} from 'react-router-dom';

const maxRestoreAttempts = 40;
let restoreAttempt = 0;
let scrollKey: string;
let isPopstate = true;

export default function ScrollManager() {
  const location = useLocation();
  const navType = useNavigationType();

  const onScroll = () => {
    if (!isPopstate) {
      sessionStorage.setItem(scrollKey, JSON.stringify(window.scrollY));
    }
  };

  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    window.addEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (navType === 'POP') isPopstate = true;
    scrollKey = `@@scroll|${location.key}|${location.pathname}`;
    isPopstate ? restoreScroll() : window.scrollTo(0, 0);

    function restoreScroll() {
      const storedKey = sessionStorage.getItem(scrollKey);
      if (!storedKey) {
        isPopstate = false;
        return;
      }

      restoreAttempt += 1;

      if (restoreAttempt > maxRestoreAttempts) {
        isPopstate = false;
        return;
      }

      const y = JSON.parse(storedKey);

      if (y > document.body.scrollHeight) {
        setTimeout(() => restoreScroll(), 50);
        return;
      }

      window.scrollTo(0, y);
      isPopstate = false;
      restoreAttempt = 0;
    }
  }, [location, navType]);

  return null;
}
