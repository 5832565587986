import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import dayjs from 'dayjs';

import {getCouponsFx, $coupons} from '../../models/coupons/coupons';

import css from './Account.module.scss';
import Image from '../../components/Image';
import Loader from '../../components/Loader';

export default function MyCoupons() {
  const {t} = useTranslation();
  const coupons = useStore($coupons);
  const isLoading = useStore(getCouponsFx.pending);
  const [activeSection, setactiveSection] = useState<'available' | 'used' | 'expired'>('available');

  useEffect(() => {
    getCouponsFx();
  }, []);

  const couponsAvailable = coupons?.data
    .filter((item: any) => !item.used && +new Date(item.validUntilDate) > +new Date())
    .sort((a: any, b: any) => {
      return +new Date(a.givenDate) > +new Date(b.givenDate) ? -1 : 1;
    });

  const couponsUsed = coupons?.data
    .filter((item: any) => item.used)
    .sort((a: any, b: any) => {
      return +new Date(a.dateUsed) > +new Date(b.dateUsed) ? -1 : 1;
    });

  const couponsExpired = coupons?.data
    .filter((item: any) => +new Date(item.validUntilDate) < +new Date() && !item.used)
    .sort((a: any, b: any) => {
      return +new Date(a.validUntilDate) > +new Date(b.validUntilDate) ? -1 : 1;
    });

  const sectionCoupons =
    activeSection === 'available' ? couponsAvailable : activeSection === 'used' ? couponsUsed : couponsExpired;

  return (
    <div className={css.MyCoupons}>
      <nav className={css.couponsNav}>
        <button data-active={activeSection === 'available'} onClick={() => setactiveSection('available')}>
          <span className="fs --25 --medium">{t('available').toUpperCase()}</span>
        </button>
        <button data-active={activeSection === 'used'} onClick={() => setactiveSection('used')}>
          <span className="fs --25 --medium">{t('used').toUpperCase()}</span>
        </button>
        <button data-active={activeSection === 'expired'} onClick={() => setactiveSection('expired')}>
          <span className="fs --25 --medium">{t('expired').toUpperCase()}</span>
        </button>
      </nav>

      <div className={css.couponsGrid}>
        {sectionCoupons?.map((item: any, i: number) => (
          <CouponItem key={i} item={item} variant={activeSection} />
        ))}
      </div>

      <div className={css.loadOrEmpty}>
        {!sectionCoupons?.length && isLoading && <Loader />}
        {!sectionCoupons?.length && !isLoading && (
          <p>
            <span className="fs --20">{t('the-list-is-empty')}</span>
          </p>
        )}
      </div>
    </div>
  );
}

function CouponItem({item, variant = 'available'}: {item: any; variant?: 'available' | 'used' | 'expired'}) {
  const {t} = useTranslation();

  return (
    <div className={css.CouponItem} data-variant={variant}>
      <div className={css.col_a}>
        {item.couponLogoId && (
          <div className={css.logo}>
            <Image alt="partner logo" src={`${item.couponLogoId}?width=200`} loading="lazy" />
          </div>
        )}

        <div className={css.meta}>
          <p className={css.title}>
            <span className="fs --18 --bold">{item.couponSetData.couponSetTitle.toUpperCase()}</span>
          </p>
          <p className={css.code}>
            <span className="fs --medium">
              {t('code')}: {item.couponCode}
            </span>
          </p>
          <p className={css.date}>
            <span className="fs --18 --medium">{dayjs(item.givenDate).format('DD-MM-YY')}</span>
          </p>
        </div>
      </div>

      <div className={css.col_b}>
        <p className={css.points}>
          {item.couponSetData.couponSetType === 'AmountCS' && (
            <>
              <span className="fs --32 --black">{item.couponSetData.amount}</span>
              <span className="fs --20 --black">ALL</span>
            </>
          )}
          {item.couponSetData.couponSetType === 'DiscountCS' && (
            <span className="fs --32 --black">{item.couponSetData.discount}%</span>
          )}
        </p>

        <p className={css.expiry}>
          {variant === 'available' && (
            <span className="fs --bold">
              {t('until')?.toUpperCase()}: {dayjs(item.validUntilDate).format('DD-MM-YY')}
            </span>
          )}
          {variant === 'used' && (
            <span className="fs --bold">
              {t('used')?.toUpperCase()}: {dayjs(item.dateUsed).format('DD-MM-YY')}
            </span>
          )}
          {variant === 'expired' && (
            <span className="fs --bold">EXP: {dayjs(item.validUntilDate).format('DD-MM-YY')}</span>
          )}
        </p>
      </div>
    </div>
  );
}
