import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import useSWR from 'swr';

import css from './Faq.module.scss';
import Head from '../components/Head';
import Loader from '../components/Loader';

export default function Faq() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const page = useSWR(`/faq-page`).data?.items[0].data;
  const itemsRes = useSWR(`/faq?$orderby=data/order/iv`);
  const items = itemsRes.data?.items;

  return (
    <div className={css.Faq}>
      <Head title={t('faq')} description={page?.metaDescription ? page.metaDescription[language] : ''} />

      <div className="container --s pad">
        <div className="page-header">
          <h1 className="fs --42 --black page-title">{t('faq')}</h1>
        </div>

        {!items && itemsRes.isValidating && <Loader />}

        {items?.map((item: any, i: number) => (
          <FaqItem key={item.id} index={i} item={item.data} />
        ))}
      </div>
    </div>
  );
}

function FaqItem({index, item}: any) {
  const {
    i18n: {language},
  } = useTranslation();
  const [isOpen, setisOpen] = useState(false);

  return (
    <div className={css.FaqItem} data-open={isOpen}>
      <button className={css.title} onClick={() => setisOpen(!isOpen)}>
        <h3 className="fs --25 --bold">
          <span>
            {index + 1 < 10 && '0'}
            {index + 1}.
          </span>
          {item.title[language]}
        </h3>
      </button>

      <div className={`fs --20 ${css.description}`} dangerouslySetInnerHTML={{__html: item.description[language]}} />
    </div>
  );
}
