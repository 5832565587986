import React, {useState, useEffect, useCallback, createRef, forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import useSWR from 'swr';
import {IoCloseOutline, IoPlay} from 'react-icons/io5';

import css from './Home.module.scss';
import Head from '../components/Head';
import Image from '../components/Image';
import Link from '../components/Link';
import Loader from '../components/Loader';
import {PartnerItem} from './Partners';

const autoplaySpeed = 6000;
let autoplayTimer: any;

export default function Home() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const [isReady, setisReady] = useState(false);
  const [slideIndex, setslideIndex] = useState(0);
  const [videoId, setvideoId] = useState(null);

  const videoModalRef = createRef<any>();

  const page = useSWR(`/home-page`).data?.items[0].data;
  const topSection = page?.topSection[language];
  const howItWorks = page?.howItWorks[language][0];
  const happyPartners = page?.happyPartners[language][0];
  const happyOffers = page?.happyOffers[language][0];
  const referYourFriends = page?.referYourFriends[language][0];

  const partnersRes = useSWR(`/merchants?$top=20`);
  const partners = partnersRes.data?.items;

  const startAutoplay = useCallback(() => {
    clearInterval(autoplayTimer);
    if (!topSection) return;

    autoplayTimer = setInterval(() => {
      const nextIndex = slideIndex < topSection.length - 1 ? slideIndex + 1 : 0;
      setslideIndex(nextIndex);
    }, autoplaySpeed);
  }, [topSection, slideIndex]);

  const onSliderScroll = (e: any) => {
    const x = e.target.scrollLeft;
    const index = Math.round(x / window.innerWidth);
    setslideIndex(index);
  };

  useEffect(() => {
    startAutoplay();
    return () => clearInterval(autoplayTimer);
  }, [startAutoplay]);

  useEffect(() => {
    setslideIndex(0);
  }, [language]);

  useEffect(() => {
    videoId ? clearInterval(autoplayTimer) : startAutoplay();
  }, [videoId, startAutoplay]);

  return (
    <div className={css.Home}>
      <h1 className="invisible">Happy</h1>

      {!page && partnersRes.isValidating && (
        <div className="loader-container">
          <Loader />
        </div>
      )}

      <VideoModal ref={videoModalRef} videoId={videoId} onClose={() => setvideoId(null)} />

      {page && (
        <>
          <Head title="" description={page?.metaDescription ? page.metaDescription[language] : ''} />

          <div className={css.topSection} data-video={!!topSection[slideIndex]?.youtubeId}>
            <div className={css.Slider} onScroll={onSliderScroll}>
              <div className={css.Slider_inner}>
                {topSection.map((item: any, i: number) => (
                  <div key={i} className={css.Slide} data-active={slideIndex === i}>
                    <div className={css.image}>
                      <Image
                        alt={item.title}
                        src={item.image}
                        srcSet={item.image}
                        sizes={`100vw`}
                        onLoad={() => setisReady(true)}
                      />
                      {item.youtubeId && (
                        <button
                          className={css.playVideoButton}
                          onClick={() => {
                            setvideoId(item.youtubeId);
                            videoModalRef.current.show();
                          }}
                        >
                          <IoPlay />
                        </button>
                      )}
                    </div>

                    {isReady && !item.youtubeId && item.image && (item.title || item.teaser) && (
                      <div className={css.meta}>
                        {item.link ? (
                          <Link
                            className={css.metaInner}
                            to={item.link}
                            onMouseEnter={() => clearInterval(autoplayTimer)}
                            onMouseLeave={() => startAutoplay()}
                          >
                            {item.title && <h2 className={`fs --48 --black`}>{item.title}</h2>}
                            {item.teaser && <p className={`fs --25 --medium`}>{item.teaser}</p>}
                          </Link>
                        ) : (
                          <div className={css.metaInner}>
                            {item.title && <h2 className={`fs --48 --black`}>{item.title}</h2>}
                            {item.teaser && <p className={`fs --25 --medium`}>{item.teaser}</p>}
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </div>

            {isReady && (
              <div className={css.SliderNav}>
                {topSection.map((item: any, i: number) => (
                  <button
                    aria-label={`Go to slide ${i + 1}`}
                    key={i}
                    data-active={slideIndex === i}
                    onClick={() => setslideIndex(i)}
                  >
                    <span />
                  </button>
                ))}
              </div>
            )}
          </div>

          <div className={`container --l ${css.mainSection}`}>
            {/* How it works comp */}
            <div className={css.HomeComp} data-comp="how-it-works">
              <div className={css.header}>
                <h2 className={`fs --48 --black ${css.title}`}>{howItWorks.title}</h2>
                <div
                  className={`fs --25 --medium ${css.teaser}`}
                  dangerouslySetInnerHTML={{__html: howItWorks.teaser}}
                />
              </div>

              <div
                className={`fs --20 ${css.description}`}
                dangerouslySetInnerHTML={{__html: howItWorks.description}}
              />

              <div className={css.action}>
                <Link className="button --white" to="/how-it-works">
                  {t('how-it-works').toUpperCase()}
                </Link>
              </div>
            </div>

            {/* Partners comp */}
            <div className={css.HomeComp} data-comp="partners">
              <div className={css.header}>
                <h2 className={`fs --48 --black ${css.title}`}>{happyPartners.title}</h2>
                <div
                  className={`fs --20 --medium ${css.teaser}`}
                  dangerouslySetInnerHTML={{__html: happyPartners.teaser}}
                />
              </div>

              <div className={css.horizontalSlider}>
                {partners?.map((item: any) => (
                  <PartnerItem key={item.id} item={item} variant="small" />
                ))}
              </div>

              <div className={css.action}>
                <Link className="button --white" to="/our-partners">
                  {happyPartners.buttonLabel.toUpperCase()}
                </Link>
              </div>
            </div>

            {/* Offers comp */}
            <div className={css.HomeComp} data-comp="offers">
              <div className={css.happyIcon} />
              <h2 className={`fs --48 --black ${css.title}`}>{happyOffers.title}</h2>
              <div className={`fs --25 ${css.teaser}`} dangerouslySetInnerHTML={{__html: happyOffers.teaser}} />

              <div className={css.action}>
                <Link className="button" to="/offers">
                  {happyOffers.buttonLabel.toUpperCase()}
                </Link>
              </div>
            </div>

            {/* Referral comp */}
            <div className={css.HomeComp} data-comp="refer-your-friends">
              <div className={css.referIcon} />
              <h2 className={`fs --38 --black ${css.title}`}>{referYourFriends.title}</h2>
              <div className={`fs --25 ${css.teaser}`} dangerouslySetInnerHTML={{__html: referYourFriends.teaser}} />

              <div className={css.action}>
                <Link className="button --transparent-white --withIcon" to="/refer-a-friend">
                  {referYourFriends.buttonLabel.toUpperCase()}
                  <div className={css.referButtonIcon}>
                    <svg>
                      <use xlinkHref={`#svg-refer-button-icon`} />
                    </svg>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

const VideoModal = forwardRef(({videoId, onClose}: any, ref: any) => {
  const [isReady, setisReady] = useState(false);

  return (
    <dialog ref={ref} className={css.VideoModal} data-ready={isReady}>
      <div
        className={css.outerClose}
        onClick={() => {
          setisReady(false);
          ref.current?.close();
          onClose();
        }}
      />

      <div className="container">
        <div className={css.inner}>
          <div className={css.topCurve}>
            <svg viewBox="0 0 1920 323">
              <use xlinkHref="#svg-header-bg" />
            </svg>
          </div>

          <div className={`pad ${css.main}`}>
            <div className={css.closeButtonContainer}>
              <button
                className={css.closeButton}
                onClick={() => {
                  setisReady(false);
                  ref.current?.close();
                  onClose();
                }}
              >
                <IoCloseOutline />
              </button>
            </div>

            <div className={css.videoContainer}>
              {videoId && (
                <iframe
                  width="100%"
                  height="100%"
                  src={`https://www.youtube.com/embed/${videoId}?controls=1&rel=0`}
                  title="YouTube video player"
                  allowFullScreen
                  onLoad={() => setisReady(true)}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </dialog>
  );
});
