import {createEffect, createStore, attach} from 'effector';
import axios from 'axios';

import {ApiResponse} from '../app_types';
import {$token, logoutFx} from '../auth/auth';
import {showToastFx} from '../components/components';

export const $coupons = createStore<any>(null);

const getCoupons = createEffect(async (token: string) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/coupons', {token});
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  return data.value.payload;
});

export const getCouponsFx = attach({
  effect: getCoupons,
  source: $token,
});

$coupons.on(getCoupons.doneData, (_, value) => value).reset(logoutFx);
