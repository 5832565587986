import React, {useEffect, useState, createRef, forwardRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import {IoClose} from 'react-icons/io5';

import {$isNavOpen, setIsNavOpen} from '../models/app';
import {getMessagesFx, $messages, $profile} from '../models/auth/auth';

import css from './Header.module.scss';
import Nav from './Nav';
import Link from './Link';
import Loader from './Loader';

export default function Header() {
  const {t} = useTranslation();
  const isNavOpen = useStore($isNavOpen);
  const profile = useStore($profile);
  const [isScrolled, setisScrolled] = useState(false);
  const [isModalOpen, setisModalOpen] = useState(false);

  const messagesModalRef = createRef<any>();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setisScrolled(window.scrollY > 200);
      if (window.innerWidth >= 1600) setIsNavOpen(false);
    });
  }, []);

  useEffect(() => {
    if (isNavOpen) {
      setisScrolled(false);
    } else {
      if (window.scrollY > 200) setisScrolled(true);
    }
  }, [isNavOpen]);

  return (
    <header className={css.Header} data-scrolled={isScrolled}>
      <div className={css.headerBg}>
        <svg viewBox="0 0 1920 323">
          <use xlinkHref="#svg-header-bg" />
        </svg>
      </div>

      {/* <MessagesModal ref={messagesModalRef} isOpen={isModalOpen} onClose={() => setisModalOpen(false)} /> */}

      <div className={css.headerContainer}>
        <div className="container pad">
          <div className={css.headerContainerInner}>
            <div className={css.brand}>
              <Link aria-label="Home page" to="/">
                <svg viewBox="0 0 179 98">
                  <use xlinkHref="#svg-logo" />
                </svg>
              </Link>
            </div>

            <div className={css.navContainer}>
              <Nav />
            </div>

            <div className={css.buttonsContainer}>
              {profile && (
                <div className={css.profileButtonContainer}>
                  <Link title={t('profile')} to={`/account/profile`}>
                    <svg viewBox="0 0 24 24">
                      <use xlinkHref="#svg-avatar" />
                    </svg>
                  </Link>
                </div>
              )}

              {/* {profile && (
                <div className={css.messagesButtonContainer}>
                  <button
                    title={t('messages')}
                    onClick={() => {
                      messagesModalRef.current?.show();
                      setisModalOpen(true);
                    }}
                  >
                    <svg viewBox="0 0 22 23">
                      <use xlinkHref="#svg-bell" />
                    </svg>
                  </button>
                </div>
              )} */}

              <div className={css.navButtonContainer}>
                <button title={t('navigation')} onClick={() => setIsNavOpen(!isNavOpen)}>
                  <svg viewBox="0 0 22 17" style={{width: '22px', height: '22px'}}>
                    <use xlinkHref="#svg-nav" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={css.headerFixedContainer}>
        <div className="container pad">
          <div className={css.headerFixedContainerInner}>
            <div className={css.brand}>
              <Link aria-label="Home page" to="/">
                <svg viewBox="0 0 179 98">
                  <use xlinkHref="#svg-logo" />
                </svg>
              </Link>
            </div>

            <button title={t('navigation')} onClick={() => setIsNavOpen(!isNavOpen)}>
              <svg viewBox="0 0 22 17" style={{width: '22px', height: '22px'}}>
                <use xlinkHref="#svg-nav" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </header>
  );
}

const MessagesModal = forwardRef(({isOpen, onClose}: any, ref: any) => {
  const {t} = useTranslation();
  const messages = useStore($messages);
  const isLoadingMessages = useStore(getMessagesFx.pending);

  useEffect(() => {
    if (isOpen) getMessagesFx();
  }, [isOpen]);

  return (
    <dialog ref={ref} className="MessagesModal">
      <div
        className="MessagesModal-outerClose"
        onClick={() => {
          ref.current?.close();
          onClose();
        }}
      />
      <div className="container --relative">
        <div className="MessagesModal-inner">
          <div className="MessagesModal-body">
            <button
              className="MessagesModal-closeButton"
              onClick={() => {
                ref.current?.close();
                onClose();
              }}
            >
              <IoClose size={26} />
            </button>

            <h2>
              <span className="fs --20 --black">{t('messages').toUpperCase()}</span>
            </h2>

            <div className="MessagesModal-grid">
              {messages?.messages.map((item: any, i: number) => (
                <div key={i} className="MessagesModalItem">
                  <h3>{item.title}</h3>
                  <div dangerouslySetInnerHTML={{__html: item.messageBody}} />
                </div>
              ))}
            </div>

            {!messages?.messages.length && (
              <>
                {isLoadingMessages ? (
                  <div className="MessagesModal-loadOrEmpty">
                    <Loader />
                  </div>
                ) : (
                  <p className="fs --18 --medium MessagesModal-loadOrEmpty">{t('no-messages')}</p>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </dialog>
  );
});
