import React, {useEffect} from 'react';
import {$inactiveMember, searchByMemberCode} from '../../models/auth/auth';
import {Link, useSearchParams} from 'react-router-dom';
import {useStore} from 'effector-react';
import {t} from 'i18next';

export const Activation = () => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const inactiveMember = useStore($inactiveMember);
  useEffect(() => {
    if (token !== null && token !== '') {
      searchByMemberCode({token: token});
    }
  }, [token]);

  // useEffect(() => {
  //   if (inactiveMember.memberStatus === 'QUICK_REGISTRATION') {
  //     // window.location.href = '/register';
  //   }
  //   if (inactiveMember.memberStatus === 'FULLY_REGISTERED') {
  //     // window.location.href = '/register';
  //   }

  //   // setToken(token);
  // }, [inactiveMember]);

  return (
    <div className={`pad`}>
      <div className="container --s pad">
        <div className="page-header">
          <h1 className="fs --42 --black page-title">{t('activation')}</h1>

          <div style={{marginTop: '4rem'}}>
            {inactiveMember && inactiveMember.memberStatus === 'QUICK_REGISTRATION' && (
              <Link className="button" to="/activate-account">
                {t('activate-account')}
              </Link>
            )}
            {inactiveMember && inactiveMember.memberStatus === 'FULLY_REGISTERED' && (
              <Link className="button" to="/login">
                {t('login')}
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
