import {createEvent, createEffect, createStore, attach, forward} from 'effector';
import axios from 'axios';
import {GraphQLClient} from 'graphql-request';
import i18n from '../i18n';
import settings from '../config/settings';

let axiosInstance = axios.create({baseURL: `${settings.domain}/${settings.contentApi}`, timeout: 40000});
let fetchClient = (url: string) =>
  axiosInstance
    .get(url)
    .then((res) => res.data)
    .catch((error) => console.log(error));

let gqlInstance = new GraphQLClient(`${settings.domain}/${settings.graphqlApi}`);
let gqlClient = (query: any) => gqlInstance.request(query);

export const $fetchClient = createStore(fetchClient);
export const $gqlClient = createStore(gqlClient);
export const $clientsInitialized = createStore(false);

export const setIsNavOpen = createEvent<boolean>();
export const $isNavOpen = createStore(false).on(setIsNavOpen, (_, isOpen) => isOpen);

/**
 * Init squidex
 */
export const initSquidexFx = createEffect(async () => {
  const url = `${settings.domain}/identity-server/connect/token`;
  const res = await fetch(url, {
    method: 'POST',
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    body: `grant_type=client_credentials&client_id=${settings.squidexClient.id}&client_secret=${settings.squidexClient.secret}&scope=squidex-api`,
  });

  const data = await res.json();

  if (data) {
    axiosInstance.defaults.headers.common['Authorization'] = `${data.token_type} ${data.access_token}`;
    gqlInstance.setHeaders({
      Authorization: `${data.token_type} ${data.access_token}`,
    });
  }

  return {fetchClient, gqlClient};
});

$fetchClient.on(initSquidexFx.doneData, (_, value) => value.fetchClient);
$gqlClient.on(initSquidexFx.doneData, (_, value) => value.gqlClient);

/**
 * Init labels
 */
const initLabels = createEffect(async (fetcher: any) => {
  const storedLabelsEN = localStorage.getItem('labelsEN');
  const storedLabelsSQ = localStorage.getItem('labelsSQ');

  if (storedLabelsEN && storedLabelsSQ) {
    i18n.addResourceBundle('en', 'labels', storedLabelsEN);
    i18n.addResourceBundle('sq', 'labels', storedLabelsSQ);
  }

  const url = `${settings.domain}${settings.contentApi}/labels`;
  const res = await fetcher(url);
  if (res) {
    let labelsEN: any = {};
    let labelsSQ: any = {};

    res.items.forEach((item: any) => {
      labelsEN[item.data.slug.iv] = item.data.text['en'];
      labelsSQ[item.data.slug.iv] = item.data.text['sq'];
    });

    localStorage.setItem('labelsEN', JSON.stringify(labelsEN));
    localStorage.setItem('labelsSQ', JSON.stringify(labelsSQ));

    i18n.addResourceBundle('en', 'labels', labelsEN);
    i18n.addResourceBundle('sq', 'labels', labelsSQ);
  }
});

export const initLabelsFx = attach({
  source: $fetchClient,
  effect: initLabels,
});

$clientsInitialized.on(initLabelsFx.finally, () => true);

/**
 * Init axios
 */
export function initAxios() {
  const apiUrl = `${settings.domain}/api`;
  axios.defaults.baseURL = apiUrl;
  axios.defaults.timeout = 40000;
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      // if (error?.response?.status === 401) logoutFx();

      return {
        error,
        errorMessage: error?.response?.data?.message ? error.response.data.message[i18n.language] : error.message,
      };
    },
  );
}

/**
 * Conditional actions
 */
forward({
  from: initSquidexFx.finally,
  to: initLabelsFx,
});
