import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import useSWR from 'swr';

import css from './ReferAFriend.module.scss';
import Loader from '../../components/Loader';
import Head from '../../components/Head';
import {$profile, $referralCode, getUserReferralCodeFx} from '../../models/auth/auth';
import {useStore} from 'effector-react';
import {showToastFx} from '../../models/components/components';
import {useNavigate} from 'react-router-dom';
import settings from '../../config/settings';

export default function ReferAFriend() {
  const itemsRes = useSWR(`/referafrienddesktop`);
  const items = itemsRes.data?.items[0].data.steps;
  const referralCode = useStore($referralCode);
  const navigate = useNavigate();
  const src = `${settings.domain}${settings.assetsApi}`;

  const profile = useStore($profile);

  useEffect(() => {
    if (!profile) {
      navigate('/login');
    } else {
      getUserReferralCodeFx();
    }
  }, [profile, navigate]);
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const copyCode = (message: string, code: string) => {
    navigator.clipboard.writeText(referralCode);
    showToastFx({text: message, status: 'success'});
  };

  return (
    <div className={`container pad ${css.referralPage}`}>
      <div className="top-section-with-bg">
        <div className="container --s pad" style={{minHeight: '30vh'}}>
          <div className="page-header">
            <h1 className="fs --42 --black page-title">{t('refer-a-friend-and-win').toUpperCase()}</h1>
          </div>
          {items && language && items[language] && (
            <div className={css.referralItems}>
              {items[language]?.map((item: any, i: number) => (
                <section key={i}>
                  <div className={`${css.icon}`}>
                    <img src={`${src}/${item.icon}`} alt={t('refer-a-friend-and-win')} />
                  </div>
                  <div className={`fs --24 ${css.description}`} dangerouslySetInnerHTML={{__html: item.description}} />
                </section>
              ))}
            </div>
          )}
          <div className={css.yourCode}>
            <div className={`${css.yourCodeTitle} fs --24`}>{itemsRes.data?.items[0].data.yourCode[language]}:</div>
            <div className={`${css.yourCodeText} fs --24`}>{referralCode}</div>
          </div>

          <div className={css.shareButton}>
            <button
              className="button"
              onClick={() => {
                copyCode(itemsRes.data?.items[0].data.shareSuccess[language], referralCode);
              }}
            >
              {itemsRes.data?.items[0].data.copyButtonText[language]}
            </button>
          </div>
          {/* {!page && pageRes.isValidating && <Loader />} */}

          {/* <div className="fs --20 textblock" dangerouslySetInnerHTML={{__html: page?.description[language]}} /> */}
        </div>
      </div>
    </div>
  );
}
//code copied to clipboard successfully
