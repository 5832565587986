import React from 'react';
import Helmet from 'react-helmet';
import {useTranslation} from 'react-i18next';

interface Props {
  title: string;
  description: string;
  image?: string;
  type?: 'article';
  canonical?: string;
}

export default function Head(props: Props) {
  const {title, description, image, type, canonical} = props;
  const {
    i18n: {language},
  } = useTranslation();

  const appName = 'Happy';

  const titleTemplate = title ? `${title} – ${appName}` : `${appName}`;
  // const defaultImage = window.location.origin + '/share.jpg';
  const defaultType = 'website';

  return (
    <Helmet>
      <html lang={language} />
      <title>{titleTemplate}</title>
      <meta name="description" content={description} />

      <meta itemProp="name" content={titleTemplate} />
      <meta itemProp="description" content={description} />
      {/* <meta itemProp="image" content={image || defaultImage} /> */}

      <meta property="og:title" content={titleTemplate} />
      <meta property="og:description" content={description} />
      {/* <meta property="og:image" content={image || defaultImage} /> */}
      <meta property="og:type" content={type || defaultType} />
      <meta property="og:url" content={window.location.href} />
      <meta property="site_name" content={appName} />

      {canonical && <link rel="canonical" href={canonical} />}
    </Helmet>
  );
}
