import {RouteObject} from 'react-router-dom';

import App from '../App';

import Home from '../pages/Home';
import TheProgram from '../pages/TheProgram';
import News, {NewsArticle} from '../pages/News';
import Offers, {Offer} from '../pages/Offers';
import Partners, {Partner, Store} from '../pages/Partners';
import Faq from '../pages/Faq';
import TermsConditions from '../pages/TermsConditions';
import ContactUs from '../pages/ContactUs';
import BecomeAPartner from '../pages/BecomeAPartner';

import LoginRequest from '../pages/Auth/LoginRequest';
import LoginVerify from '../pages/Auth/LoginVerify';
import Register from '../pages/Auth/Register';

import Account from '../pages/Account/Account';
import Profile from '../pages/Account/Profile';
import ReferAFriend from '../pages/Account/ReferAFriend';
import PointsHistory from '../pages/Account/PointsHistory';
import MyFavoriteOffers from '../pages/Account/MyFavoriteOffers';
import MyCoupons from '../pages/Account/MyCoupons';
import Booklets, {BookletDetail} from '../pages/Account/Booklets';

import Page404 from '../components/Page404';
import {Download} from '../pages/Download';
import {Activation} from '../pages/Account/Activation';
import ActivateMember from '../pages/Auth/ActivateMember';
import PrivacyPolicy from '../pages/PrivacyPolicy';

const pages: RouteObject[] = [
  {
    path: '',
    element: <Home />,
  },
  {
    path: 'how-it-works',
    element: <TheProgram slug="how-it-works" />,
  },
  {
    path: 'how-to-create-an-account',
    element: <TheProgram slug="how-to-create-an-account" />,
  },
  {
    path: 'points-gathering',
    element: <TheProgram slug="points-gathering" />,
  },
  {
    path: 'benefits',
    element: <TheProgram slug="benefits" />,
  },
  {
    path: 'co-branded-cards',
    element: <TheProgram slug="co-branded-cards" />,
  },
  {
    path: 'our-partners',
    element: <Partners />,
  },
  {
    path: 'our-partners/:slug',
    element: <Partner />,
  },
  {
    path: 'our-partners/:slug/:slug',
    element: <Store />,
  },
  {
    path: 'faq',
    element: <Faq />,
  },
  {
    path: 'terms-conditions',
    element: <TermsConditions />,
  },
  {
    path: 'privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    path: 'become-a-partner',
    element: <BecomeAPartner />,
  },
  {
    path: 'news',
    element: <News />,
  },
  {
    path: 'news/:slug',
    element: <NewsArticle />,
  },
  {
    path: 'offers/:type/:slug',
    element: <Offer />,
  },
  {
    path: 'offers',
    element: <Offers />,
  },
  {
    path: 'offers/:slug',
    element: <Offer />,
  },
  {
    path: 'contact-us',
    element: <ContactUs />,
  },
  {
    path: 'login',
    element: <LoginRequest />,
  },
  {
    path: 'login/verify',
    element: <LoginVerify />,
  },
  {
    path: 'register',
    element: <Register />,
  },
  {
    path: 'account/activation',
    element: <Activation />,
  },
  {
    path: 'activate-account',
    element: <ActivateMember />,
  },
  {
    path: 'account',
    element: <Account />,
    children: [
      {
        path: 'profile',
        element: <Profile />,
      },
      {
        path: 'points-history',
        element: <PointsHistory />,
      },
      {
        path: 'my-favorite-offers',
        element: <MyFavoriteOffers />,
      },
      {
        path: 'my-coupons',
        element: <MyCoupons />,
      },
      {
        path: 'booklets',
        element: <Booklets />,
      },
      {
        path: 'booklets/:slug',
        element: <BookletDetail />,
      },
    ],
  },
  {
    path: 'refer-a-friend',
    element: <ReferAFriend />,
  },
  {
    path: 'app/download',
    element: <Download />,
  },
  {
    path: '*',
    element: <Page404 />,
  },
];

const routes: RouteObject[] = [
  {
    element: <App />,
    children: pages,
  },
  {
    path: '/sq',
    element: <App />,
    children: pages,
  },
];

export default routes;
