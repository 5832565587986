import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {useStore} from 'effector-react';
import DatePicker, {registerLocale} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import sq from 'date-fns/locale/sq';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {validateEmail} from '../../lib/utils';

import {
  $profile,
  $registrationFlow,
  createMemberFx,
  activateMemberFx,
  updateRegistrationFlow,
  $inactiveMember,
  activateInactiveMember,
} from '../../models/auth/auth';

import css from './Auth.module.scss';
import Dropdown from '../../components/Dropdown';
import FormControlError from '../../components/FormControlError';
import Link from '../../components/Link';

registerLocale('sq', sq);
dayjs.extend(customParseFormat);

const minimumAgeYears = 16;

export default function ActivateAccount() {
  const {t} = useTranslation();
  const navigate = useNavigate();
  const registrationFlow = useStore($inactiveMember);
  // const profile = useStore($inactiveMember);

  useEffect(() => {
    if (!registrationFlow) {
      navigate('/');
      // } else {
      //   if (!registrationFlow.mobile) {
      //     navigate('/login');
      //   }
    }
  }, [registrationFlow, navigate]);

  return (
    <div className="container pad">
      <div className="page-header">
        <h1 className="fs --42 --black page-title">{t('registration-page').toUpperCase()}</h1>
        <div className="fs --25 --medium page-intro">
          <strong>{t('new-here')}?</strong> {t('fill-in-the-registration-form')}
        </div>
      </div>

      {registrationFlow.isActivated ? (
        <>
          <div className="page-header">
            <div className="medium page-intro">
              <div className="fs --25 ">{t('success-message')}</div>
            </div>
          </div>
          <div style={{textAlign: 'center'}}>
            <Link className="button" to="/login">
              {t('login')}
            </Link>
          </div>
        </>
      ) : (
        <RegistrationForm />
      )}
    </div>
  );
}

function RegistrationForm() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const registrationFlow = useStore($inactiveMember);
  const isLoading = useStore(createMemberFx.pending);
  const isActivating = useStore(activateMemberFx.pending);

  const {register, setValue, getValues, watch, trigger, handleSubmit, formState} = useForm<any>({
    defaultValues: registrationFlow,
  });

  const dateOfBirth = watch().dateOfBirth;

  useEffect(() => {
    return () => {
      updateRegistrationFlow(getValues());
    };
  }, [getValues]);

  const onSubmit = async (values: any) => {
    updateRegistrationFlow(values);

    const payload = {
      ...values,
      dateOfBirth: dayjs(values.dateOfBirth).format('YYYY-MM-DD'),
      isMale: !!values.isMale,
    };

    if (registrationFlow.cardNumber) {
      activateInactiveMember({...payload, token: registrationFlow.token});
    } else {
      // createMemberFx(payload);
    }
  };

  const formatWrongDate = (dateString: string) => {
    if (dateOfBirth instanceof Date) {
      return new Date(dateString);
    } else {
      const dateArray = dateString ? dateString.split('T') : [];
      return new Date(dateArray[0]);
    }
  };

  return (
    <form className={css.RegistrationForm}>
      <fieldset>
        <div className="form-control">
          <input {...register('firstname', {required: true})} type="text" placeholder={t('first-name')} />
          {formState.isSubmitted && formState.errors.firstname && <FormControlError />}
        </div>

        <div className="form-control">
          <input {...register('lastname', {required: true})} type="text" placeholder={t('last-name')} />
          {formState.isSubmitted && formState.errors.lastname && <FormControlError />}
        </div>
        <div className="form-control --dropdown">
          <Dropdown
            placeholder={t('gender')}
            initialIndex={
              registrationFlow.isMale !== null && registrationFlow.isMale !== undefined
                ? registrationFlow.isMale
                  ? 1
                  : 2
                : 0
            }
            options={[
              {value: null, label: '-'},
              {label: t('male'), value: 1},
              {label: t('female'), value: 2},
            ]}
            onChange={(option) => {
              setValue('isMale', option.value === 1 ? 1 : 0);
              trigger();
            }}
          />
          <input {...register('isMale', {required: true})} type="hidden" />
          {formState.isSubmitted && formState.errors.isMale && <FormControlError />}
        </div>

        <div className="form-control">
          <DatePicker
            locale={language}
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={80}
            selected={null}
            maxDate={dayjs().subtract(minimumAgeYears, 'years').toDate()}
            value={dateOfBirth ? dayjs(dateOfBirth).format('DD/MM/YYYY') : ''}
            placeholderText={t('date-of-birth')}
            onChange={(date) => {
              setValue('dateOfBirth', date);
              trigger();
            }}
          />
          {/* )} */}
          <input {...register('dateOfBirth', {required: true})} type="hidden" />
          {formState.isSubmitted && formState.errors.dateOfBirth && <FormControlError />}
        </div>

        <div className="form-control">
          <input {...register('mobile')} type="text" value={registrationFlow.mobile} readOnly />
        </div>

        <div className="form-control">
          <input
            {...register('email', {
              validate: {email: (value) => (value ? validateEmail(value) : true)},
            })}
            type="email"
            placeholder="Email"
          />
          {formState.isSubmitted && formState.errors.email && <FormControlError />}
        </div>

        <div className="form-control">
          <input {...register('town', {required: true})} type="text" placeholder={t('city')} />
          {formState.isSubmitted && formState.errors.town && <FormControlError />}
        </div>

        <div className="form-control">
          <input {...register('addressLine')} type="text" placeholder={t('address')} />
          {formState.isSubmitted && formState.errors.addressLine && <FormControlError />}
        </div>

        {!!registrationFlow.cardNumber && (
          <div className="form-control">
            <input {...register('cardNumber')} type="text" readOnly />
          </div>
        )}
        {!registrationFlow.referralCode && (
          <div className="form-control">
            <input {...register('referralCode')} type="text" placeholder={t('referral-code')} />
          </div>
        )}
        <div className="form-control --checkbox terms-consent">
          <input {...register('termsConsent', {required: true})} type="checkbox" id="termsConsent" />
          <label htmlFor="termsConsent">
            {t('i-have-read-and-agreed-with-the')} <Link to="/terms-conditions">{t('terms-and-conditions')}</Link>
          </label>
          {formState.isSubmitted && formState.errors.termsConsent && <FormControlError />}
        </div>
      </fieldset>

      <button type="button" className="button" disabled={isLoading || isActivating} onClick={handleSubmit(onSubmit)}>
        {registrationFlow.cardNumber ? t('activate-your-card').toUpperCase() : t('register').toUpperCase()}
      </button>
    </form>
  );
}
