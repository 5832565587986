import {createEvent, createEffect, createStore, attach, guard, forward} from 'effector';
import axios from 'axios';

import {ApiResponse} from '../app_types';
import {RegistrationType, ProfileType, ActivationType} from './auth_types';
import {showToastFx} from '../components/components';

export const tokenKey = 'token_v1';

/**
 * Stores
 * ========================================================
 */
export const $token = createStore<string>('');
export const $profile = createStore<ProfileType | null>(null);
export const $inactiveMember = createStore<ActivationType>({
  firstname: '',
  lastname: '',
  isMale: null,
  mobile: '',
  email: '',
  dateOfBirth: '',
  town: '',
  addressLine: '',
  termsConsent: false,
  cardNumber: '',
  poinBalance: 0,
  club: '',
  referralCode: '',
  token: '',
});

export const $registrationFlow = createStore<RegistrationType>({
  firstname: '',
  lastname: '',
  isMale: null,
  mobile: '',
  email: '',
  dateOfBirth: '',
  town: '',
  addressLine: '',
  termsConsent: false,
  cardNumber: '',
  poinBalance: 0,
  club: '',
  referralCode: '',
});
export const $OTP = createStore({
  mobile: '',
  timestamp: 0,
});
export const $pointsHistory = createStore<any>(null);
export const $messages = createStore<any>(null);
export const $favoriteOffers = createStore<string[] | null>(null);

/**
 * Token
 * ========================================================
 */
export const setToken = createEvent<string>();
export const initTokenFx = createEffect(() => {
  const storedToken = localStorage.getItem(tokenKey);
  if (storedToken) return JSON.parse(storedToken);
  return '';
});

$token.on(setToken, (_, token) => token).on(initTokenFx.doneData, (_, token) => token);

/**
 * OTP Validation
 * ========================================================
 */
export const OTPRequestFx = createEffect(async (params: {mobile: RegistrationType['mobile']}) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/one-time-pin/request', params);
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  return data.value;
});

export const OTPValidateFx = createEffect(async (params: {mobile: RegistrationType['mobile']; pin: string}) => {
  const otpValidateRes: ApiResponse = await axios.post('/members/one-time-pin/validate', params);

  if (otpValidateRes.errorMessage) {
    showToastFx({text: otpValidateRes.errorMessage, status: 'error'});
    return null;
  }

  if (!otpValidateRes?.data?.value) return null;

  const validateMobileRes: ApiResponse = await axios.post('/members/validate-mobile', params);
  if (validateMobileRes.data) {
    if (validateMobileRes.data.message) return false;
    return validateMobileRes.data;
  }
});

$token.on(OTPValidateFx.doneData, (_, data) => data?.token);
$OTP.on(OTPRequestFx, (_, params) => {
  return {
    mobile: params.mobile,
    timestamp: +new Date(),
  };
});
/**
 * Activation
 * ========================================================
 */
export const updateActivationFlow = createEvent<RegistrationType | Partial<RegistrationType>>();

export const searchByMemberCode = createEffect(async ({token}: any) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/SearchByMemberCode', {memberCode: token});
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});

  return {
    firstname: data.value.firstname,
    lastname: data.value.lastname,
    isMale: data.value.isMale,
    mobile: data.value.mobile,
    email: data.value.email,
    dateOfBirth: data.value.dateOfBirth,
    town: data.value.town,
    addressLine: data.value.addressLine,
    termsConsent: data.value.termsConsent,
    cardNumber: data.value.cardNumber,
    poinBalance: data.value.poinBalance,
    club: data.value.club,
    referralCode: data.value.referralCode,
    token: data.token,
    memberStatus: data.value.memberStatus,
  } as ActivationType;
});
export const activateInactiveMember = createEffect(async (values: any) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/activate', values);
  // if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  return {value: true, message: 'Success'};
  // return data;
});
export const searchByMemberCodeFx = attach({
  effect: searchByMemberCode,
  source: $inactiveMember,
});
$inactiveMember
  .on(searchByMemberCode.doneData, (_, values) => {
    const data = values as ActivationType;
    return data;
  })
  .on(activateInactiveMember.doneData, (state, values) => {
    updateActivationFlow({
      firstname: state.firstname,
      lastname: state.lastname,
      isMale: state.isMale,
      mobile: state.mobile,
      email: state.email,
      dateOfBirth: state.dateOfBirth,
      town: state.town,
      addressLine: state.addressLine,
      termsConsent: state.termsConsent,
      cardNumber: state.cardNumber,
      poinBalance: state.poinBalance,
      club: state.club,
      referralCode: state.referralCode,
    });
    login(state.token);
    setToken(state.token);
    window.location.href = '/';
    return {
      ...state,
      isActivated: values.value,
    };
  });
/**
 * Register
 * ========================================================
 */
export const updateRegistrationFlow = createEvent<RegistrationType | Partial<RegistrationType>>();
export const resetRegistrationFlow = createEvent();

export const createMemberFx = createEffect(async (params: RegistrationType) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/create', {...params, fromWeb: true});
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  setToken(data.value);
  return data.value;
});

const activateMember = createEffect(async ({values, token}: any) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/activate', {...values, token});
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  return data;
});

export const activateMemberFx = attach({
  effect: activateMember,
  source: $token,
  mapParams: (values, $token) => ({values, token: $token}),
});

$registrationFlow.on(updateRegistrationFlow, (state, values) => ({...state, ...values})).reset(resetRegistrationFlow);

/**
 * Login / Logout
 * ========================================================
 */
async function sendLoginMetadata(token: string) {
  axios.post('/members/login', {
    token,
    firebaseToken: null,
    deviceOS: null,
    deviceID: null,
    metadata: null,
  });
}

const login = createEffect((token: string) => {
  localStorage.setItem(tokenKey, JSON.stringify(token));
  sendLoginMetadata(token);
});

export const loginFx = attach({
  source: $token,
  effect: login,
});

export const logoutFx = createEffect(() => {
  localStorage.removeItem(tokenKey);
});

$token.reset(logoutFx);

/**
 * Member
 * ========================================================
 */
const getMember = createEffect(async (token: string) => {
  const {data}: ApiResponse = await axios.post('/members/get', {token});
  return data.value;
});

export const getMemberFx = attach({
  effect: getMember,
  source: $token,
});

const updateMemberData = createEffect(async ({params, token}: {params: ProfileType; token: string}) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/update', {...params, token});
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  return data?.value;
});

export const updateMemberDataFx = attach({
  effect: updateMemberData,
  source: $token,
  mapParams: (params: ProfileType, $token) => ({params, token: $token}),
});

export const updateMobileOTPFx = createEffect(async (mobile: string) => {
  const {errorMessage}: ApiResponse = await axios.post('/members/update-mobile/one-time-pin', {mobile});
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  return true;
});

const updateMemberMobile = createEffect(async ({newMobileNumber, token}: {newMobileNumber: string; token: string}) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/update-mobile', {
    newMobileNumber,
    token,
  });
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});

  return data.value;
});

export const updateMemberMobileFx = attach({
  effect: updateMemberMobile,
  source: $token,
  mapParams: (newMobileNumber: string, $token) => ({newMobileNumber, token: $token}),
});

const getPointsHistory = createEffect(
  async ({pageNumber, pageSize, token}: {pageNumber: number; pageSize: number; token: string}) => {
    const {data}: ApiResponse = await axios.post('/members/point-log-history', {
      pageNumber,
      pageSize,
      token,
    });
    return {pageNumber, totalItems: data.value.filterResults.totalResults, data: data.value.data};
  },
);

export const getPointsHistoryFx = attach({
  effect: getPointsHistory,
  source: $token,
  mapParams: ({pageNumber, pageSize}: {pageNumber: number; pageSize: number}, $token) => ({
    pageNumber,
    pageSize,
    token: $token,
  }),
});

const getMessages = createEffect(async (token: string) => {
  const {data}: ApiResponse = await axios.post('/members/messages', {
    token,
    read: null,
    pageNumber: 1,
    pageSize: 100,
  });
  return data.value;
});

export const getMessagesFx = attach({
  effect: getMessages,
  source: $token,
});

const markMessageAsRead = createEffect(async ({id, token}: {id: string; token: string}) => {
  const {data}: ApiResponse = await axios.post('/members/read-message', {id, token});
  return data.value;
});

export const markMessageAsReadFx = attach({
  effect: markMessageAsRead,
  source: $token,
  mapParams: (id: string, $token) => ({id, token: $token}),
});

const markAllMessagesAsRead = createEffect(async (token: string) => {
  const {data}: ApiResponse = await axios.post('/members/read-all-messages', {token});
  return data.value;
});

export const markAllMessagesAsReadFx = attach({
  effect: markAllMessagesAsRead,
  source: $token,
});

const getFavorites = createEffect(async (token: string) => {
  const {data}: ApiResponse = await axios.post('/members/get-favorites', {token});
  return data?.value;
});

export const getFavoritesFx = attach({
  effect: getFavorites,
  source: $token,
});

const addFavorites = createEffect(async ({couponsetCodes, token}: {couponsetCodes: string[]; token: string}) => {
  const {data}: ApiResponse = await axios.post('/members/add-favorites', {couponsetCodes, token});
  return data?.value;
});

export const addFavoritesFx = attach({
  effect: addFavorites,
  source: $token,
  mapParams: (couponsetCodes: string[], $token) => ({couponsetCodes, token: $token}),
});

const removeFavorites = createEffect(async ({couponsetCodes, token}: {couponsetCodes: string[]; token: string}) => {
  const {data}: ApiResponse = await axios.post('/members/remove-favorites', {couponsetCodes, token});
  return data?.value;
});

export const removeFavoritesFx = attach({
  effect: removeFavorites,
  source: $token,
  mapParams: (couponsetCodes: string[], $token) => ({couponsetCodes, token: $token}),
});

const deleteMember = createEffect(async (token: string) => {
  const {data}: ApiResponse = await axios.post('/members/delete', {token});
  return data;
});

export const deleteMemberFx = attach({
  effect: deleteMember,
  source: $token,
});

$OTP.on(updateMobileOTPFx, (_, mobile) => {
  return {
    mobile,
    timestamp: +new Date(),
  };
});

export const resetPointsHistory = createEvent();

$profile.on(getMemberFx.doneData, (_, value) => value).reset(logoutFx);

$pointsHistory
  .on(getPointsHistory.doneData, (state, value) => {
    return {
      ...state,
      totalItems: value.totalItems,
      items: {
        ...state?.items,
        [value.pageNumber]: value.data,
      },
    };
  })
  .reset([logoutFx, resetPointsHistory]);

$messages.on(getMessagesFx.doneData, (_, value) => value).reset(logoutFx);

$favoriteOffers.on(getFavoritesFx.doneData, (_, value) => value).reset(logoutFx);

/**
 * Refer a friend
 * ======================================================
 */
const getUserReferralCode = createEffect(async (token: string) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/referral-code', {token});
  if (errorMessage) showToastFx({text: errorMessage, status: 'error'});
  return data?.value;
});

export const getUserReferralCodeFx = attach({effect: getUserReferralCode, source: $token});

export const $referralCode = createStore('')
  .on(getUserReferralCodeFx.doneData, (_, value) => value)
  .reset(logoutFx);

/**
 * Conditional actions
 * ========================================================
 */
guard({
  source: initTokenFx.doneData,
  filter: (token) => token,
  target: getMemberFx,
});

guard({
  source: createMemberFx.doneData,
  filter: (token) => token,
  target: loginFx,
});

forward({
  from: loginFx.doneData,
  to: resetRegistrationFlow,
});

forward({
  from: loginFx.doneData,
  to: getMemberFx,
});

forward({
  from: updateMemberDataFx.doneData,
  to: getMemberFx,
});

forward({
  from: markMessageAsReadFx.doneData,
  to: getMessagesFx,
});

forward({
  from: markAllMessagesAsReadFx.doneData,
  to: getMessagesFx,
});

forward({
  from: addFavoritesFx.doneData,
  to: getFavoritesFx,
});

forward({
  from: removeFavoritesFx.doneData,
  to: getFavoritesFx,
});

guard({
  source: deleteMemberFx.doneData,
  filter: (value) => value,
  target: logoutFx,
});
