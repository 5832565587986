import {useStore} from 'effector-react';
import {$popup, clearPopup, setPopup} from '../models/components/components';
import css from './Popup.module.scss';
import {buyCouponFx} from '../models/offers/offers';
import {t} from 'i18next';

export default function Popup() {
  const popup = useStore($popup);

  if (!popup) return null;
  return (
    <div className={`${css.popup} dol`}>
      <div className={`${css.popupInner}`}>
        <div className={css.popupClose} onClick={() => clearPopup()}>
          <svg>
            <use xlinkHref="#svg-close" />
          </svg>
        </div>
        <div className={css.smiley}>
          <svg>
            <use xlinkHref="#smiley" />
          </svg>
        </div>
        <div className={css.popupContent}>
          {popup.id === 'confirm-coupon-purchase' && popup.couponCode !== undefined && (
            <ConfirmCouponPurchasePopup couponCode={popup.couponCode} />
          )}
        </div>
        <div className={css.popupBackground}>
          <svg>
            <use xlinkHref="#logout-bg" />
          </svg>
        </div>
      </div>
    </div>
  );
}

function ConfirmCouponPurchasePopup(props: {couponCode: string}) {
  const onCouponBuy = () => {
    clearPopup();
    buyCouponFx(props.couponCode);
  };
  return (
    <>
      <div className={`${css.popupContentText} fs --medium --white --18`}>{t('confirm-coupon-purchase')}</div>
      <button className="button --white-border" onClick={() => onCouponBuy()}>
        {t('buy-a-coupon').toUpperCase()}
      </button>
    </>
  );
}
