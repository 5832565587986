import React from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import useSWR from 'swr';
import {IoArrowBack} from 'react-icons/io5';

import css from './News.module.scss';
import Head from '../components/Head';
import Image from '../components/Image';
import Link from '../components/Link';
import Loader from '../components/Loader';

export default function News() {
  const {
    t,
    i18n: {language},
  } = useTranslation();

  const page = useSWR(`/news-page`).data?.items[0].data;
  const itemsRes = useSWR(`/news`);
  const items = itemsRes.data?.items;

  return (
    <div className={css.News}>
      <Head title={t('news')} description={page?.metaDescription ? page.metaDescription[language] : ''} />

      <div className="container --s pad">
        <div className="page-header">
          <h1 className="fs --42 --black page-title">{t('news').toUpperCase()}</h1>
        </div>

        {!items && itemsRes.isValidating && <Loader />}

        <div className={css.grid}>
          {items?.map((item: any) => (
            <NewsItem key={item.id} {...item.data} />
          ))}
        </div>
      </div>
    </div>
  );
}

function NewsItem(item: any) {
  const {
    i18n: {language},
  } = useTranslation();

  return (
    <Link className={css.NewsItem} to={`/news/${item.slug.iv}`}>
      <h3 className={`fs --28 --medium ${css.title}`}>{item.title[language]}</h3>
      <p className={`fs --20`}>{item.teaser[language]}</p>
    </Link>
  );
}

export function NewsArticle() {
  const {
    i18n: {language},
  } = useTranslation();
  const navigate = useNavigate();
  const {slug} = useParams();

  const page = useSWR(`/news?$filter=data/slug/iv eq '${slug}'`).data?.items[0].data;

  if (!page)
    return (
      <div className="loader-container">
        <Loader />
      </div>
    );

  return (
    <article className={css.NewsArticle}>
      <Head title={page.title[language]} description={page?.metaDescription ? page.metaDescription[language] : ''} />

      <div className="container pad">
        <div className={css.inner}>
          <div className="container --s pad">
            <button aria-label="Back" className="back-button" onClick={() => navigate(-1)}>
              <IoArrowBack size={24} />
            </button>
            <div className="page-header">
              <h1 className="fs --42 --black page-title">{page.title[language]}</h1>
            </div>

            {page.image?.iv && (
              <div className={css.image}>
                <Image alt={page.title[language]} src={page.image.iv} />
              </div>
            )}

            <div
              className={`fs --20 textblock ${css.description}`}
              dangerouslySetInnerHTML={{__html: page.description[language]}}
            />
          </div>
        </div>
      </div>
    </article>
  );
}
