import React, {Component, createRef} from 'react';
import {IoChevronDown} from 'react-icons/io5';

import css from './Dropdown.module.scss';

export type DropdownOption = {
  label: string;
  value: any;
};

export type DropdownProps = {
  placeholder?: string;
  initialIndex?: number;
  options: DropdownOption[];
  onChange: (selected: DropdownOption) => void;
};

type State = {
  activeIndex: number | undefined;
  isOpen: boolean;
};

export default class Dropdown extends Component<DropdownProps, State> {
  constructor(props: DropdownProps) {
    super(props);
    this.setIndex = this.setIndex.bind(this);
  }

  state: State = {
    activeIndex: undefined,
    isOpen: false,
  };

  optionsRef = createRef<HTMLDivElement>();

  componentDidMount() {
    if (this.props.initialIndex !== undefined) this.setState({activeIndex: this.props.initialIndex});
  }

  componentDidUpdate(prevProps: DropdownProps, prevState: State) {
    const {isOpen} = this.state;
    if (isOpen) this.optionsRef.current?.scrollTo({top: 0});
  }

  onOptionClick = (index: number) => {
    this.setState({activeIndex: index, isOpen: false});
    this.props.onChange(this.props.options[index]);
  };

  setIndex(index: number | undefined) {
    this.setState({activeIndex: index});
  }

  render() {
    const {placeholder, options} = this.props;
    const {activeIndex, isOpen} = this.state;

    return (
      <>
        {isOpen && <div className={css.Dropdown_outerClose} onClick={() => this.setState({isOpen: false})} />}

        <div className={css.Dropdown} data-open={isOpen} data-active={!placeholder || activeIndex !== undefined}>
          <button type="button" className={css.Dropdown_button} onClick={() => this.setState({isOpen: !isOpen})}>
            <span>
              {activeIndex === undefined ? (placeholder ? placeholder : options[0].label) : options[activeIndex].label}
            </span>
            <IoChevronDown size={24} />
          </button>

          <div ref={this.optionsRef} className={css.Dropdown_options}>
            {options.map((option: any, i: number) => (
              <button
                key={i}
                type="button"
                className={css.option}
                disabled={i === activeIndex}
                onClick={() => this.onOptionClick(i)}
              >
                <span className={css.optionText}>{option.label}</span>
              </button>
            ))}
          </div>
        </div>
      </>
    );
  }
}
