import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useLocation, Link, useNavigate} from 'react-router-dom';
import {useStore} from 'effector-react';
import settings from '../config/settings';

import {setIsNavOpen, $isNavOpen} from '../models/app';
import {$profile, logoutFx} from '../models/auth/auth';

import css from './Nav.module.scss';
import LinkComp from './Link';

export default function Nav() {
  const {t, i18n} = useTranslation();
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const isNavOpen = useStore($isNavOpen);
  const profile = useStore($profile);

  const locale = i18n.language;
  const currPath = locale === settings.defaultLocale ? pathname : pathname.slice(3);
  const closeNav = () => setIsNavOpen(false);

  const navRef = useRef<HTMLElement>(null);

  useEffect(() => {
    navRef.current?.scrollTo({top: 0});
  }, [isNavOpen]);

  useEffect(() => {
    const urlLocale = pathname === '/sq' || pathname.startsWith('/sq/') ? 'sq' : 'en';
    if (urlLocale !== i18n.language) i18n.changeLanguage(urlLocale);
    closeNav();
  }, [pathname, i18n]);

  const onLogout = () => {
    logoutFx();
    const homeLink = i18n.language === settings.defaultLocale ? '/' : `/${i18n.language}/`;
    navigate(homeLink);
  };

  return (
    <>
      {isNavOpen && <div className={css.Nav_outer} onClick={closeNav} />}
      <nav ref={navRef} className={css.Nav}>
        <NavItem label={t('the-program')}>
          <ul>
            <li>
              <LinkComp to="/how-it-works">
                <span>{t('how-it-works')}</span>
              </LinkComp>
            </li>
            <li>
              <LinkComp to="/how-to-create-an-account">
                <span>{t('how-to-create-an-account')}</span>
              </LinkComp>
            </li>
            <li>
              <LinkComp to="/points-gathering">
                <span>{t('points-gathering')}</span>
              </LinkComp>
            </li>
            <li>
              <LinkComp to="/benefits">
                <span>{t('benefits')}</span>
              </LinkComp>
            </li>
            <li>
              <LinkComp to="/co-branded-cards">
                <span>{t('co-branded-cards')}</span>
              </LinkComp>
            </li>
          </ul>
        </NavItem>

        <NavItem to="/our-partners" label={t('our-partners')} />

        <NavItem label={t('support')}>
          <ul>
            <li>
              <LinkComp to="/faq">
                <span>{t('faq')}</span>
              </LinkComp>
            </li>
            <li>
              <LinkComp to="/terms-conditions">
                <span>{t('terms-and-conditions')}</span>
              </LinkComp>
            </li>
          </ul>
        </NavItem>

        <NavItem to="/news" label={t('news')} />

        <NavItem to="/offers" label={t('offers')} />

        <NavItem to="/contact-us" label={t('contact-us')} />

        <NavItem label={i18n.language.toUpperCase()}>
          <ul>
            <li>
              <Link to={currPath} replace data-active={locale === 'en'} onClick={() => i18n.changeLanguage('en')}>
                <span>English</span>
              </Link>
            </li>
            <li>
              <Link
                to={`/sq${currPath}`}
                replace
                data-active={locale === 'sq'}
                onClick={() => i18n.changeLanguage('sq')}
              >
                <span>Shqip</span>
              </Link>
            </li>
          </ul>
        </NavItem>

        {!profile && <NavItem label={t('login')} to="/login" name="account" />}

        {profile && (
          <NavItem label={`${profile?.firstname} ${profile.lastname && profile?.lastname.slice(0, 1)}.`} name="account">
            <ul>
              <li className={css.memberPoints}>
                <span className="fs --28 --black">{profile?.poinBalance} </span>
                <span className="fs --bold">{t('points').toLowerCase()}</span>
              </li>
              <li>
                <LinkComp to="/account/profile">
                  <span>{t('profile')}</span>
                </LinkComp>
              </li>
              <li>
                <LinkComp to="/account/points-history">
                  <span>{t('points-history')}</span>
                </LinkComp>
              </li>
              <li>
                <LinkComp to="/account/my-favorite-offers">
                  <span>{t('my-favorite-offers')}</span>
                </LinkComp>
              </li>
              <li>
                <LinkComp to="/account/my-coupons">
                  <span>{t('my-coupons')}</span>
                </LinkComp>
              </li>
              <li>
                <LinkComp to="/account/booklets">
                  <span>{t('booklets')}</span>
                </LinkComp>
              </li>
              <li>
                <LinkComp to="/refer-a-friend">
                  <span>{t('refer-a-friend')}</span>
                </LinkComp>
              </li>
              <li>
                <button onClick={onLogout}>
                  <span>{t('logout')}</span>
                </button>
              </li>
            </ul>
          </NavItem>
        )}
      </nav>
    </>
  );
}

interface NavItemProps {
  label: string;
  to?: string;
  name?: string;
  children?: JSX.Element | JSX.Element[];
}

export const NavItem = ({to, label, name = '', children}: NavItemProps) => {
  const {pathname} = useLocation();
  const {i18n} = useTranslation();
  const [isOpen, setisOpen] = useState(false);

  const path = i18n.language === settings.defaultLocale ? pathname : pathname.slice(3);

  useEffect(() => {
    setisOpen(false);
  }, [pathname]);

  return (
    <div
      className={css.NavItem}
      data-open={isOpen}
      data-name={name}
      onMouseLeave={() => setisOpen(false)}
      onMouseEnter={() => setisOpen(true)}
    >
      {to ? (
        <LinkComp className={css.NavItem_title} to={to} data-active={to === path}>
          {name === 'account' && (
            <svg viewBox="0 0 24 24">
              <use xlinkHref="#svg-avatar" />
            </svg>
          )}
          <span className="fs --19 --black">{label}</span>
        </LinkComp>
      ) : (
        <div className={css.NavItem_title}>
          {name === 'account' && (
            <svg viewBox="0 0 24 24">
              <use xlinkHref="#svg-avatar" />
            </svg>
          )}
          <span className={`fs --19 --black `}>
            <span className={css.text}>{label}</span>
          </span>
        </div>
      )}

      {children && children}
    </div>
  );
};
