import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import settings from './config/settings';

i18n.use(initReactI18next).init({
  supportedLngs: settings.supportedLocales,
  lng: settings.defaultLocale,
  defaultNS: 'labels',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: {
      labels: {},
    },
    sq: {
      labels: {},
    },
  },
});

export default i18n;
