import {useEffect} from 'react';

export const Download = () => {
  const userAgent = navigator.userAgent.toLowerCase();
  const isIos = userAgent.match(/(ipad|iphone|ipod)/i) ? true : false;
  const isAndroid = userAgent.indexOf('android') > -1;
  useEffect(() => {
    if (isIos) window.location.href = 'https://apps.apple.com/us/app/happy-loyalty-app/id1637864695';
    if (isAndroid) window.location.href = 'https://play.google.com/store/apps/details?id=com.app.qivos';
  }, []);

  return (
    <div className={`pad`}>
      <div className="container --s pad">
        <div className="page-header">
          <h1 className="fs --42 --black page-title">
            {isIos && 'Redirect to apple store'}
            {isAndroid && 'Redirect to google play'}
            {!isIos && !isAndroid && 'Download app page'}
          </h1>
        </div>
      </div>
    </div>
  );
};
