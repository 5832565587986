import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useStore} from 'effector-react';
import useSWR from 'swr';
import {gqlGetOffers} from '../../lib/graphql_queries';

import {$gqlClient} from '../../models/app';
import {$favoriteOffers, getFavoritesFx} from '../../models/auth/auth';

import css from './Account.module.scss';
import {OfferItem} from '../Offers';
import Loader from '../../components/Loader';

export default function MyFavoriteOffers() {
  const {
    t,
    i18n: {language},
  } = useTranslation();
  const gqlClient = useStore($gqlClient);
  const favoriteOffers = useStore($favoriteOffers);
  const isLoadingFavorites = useStore(getFavoritesFx.pending);

  const offersRes = useSWR(gqlGetOffers(language), gqlClient);
  const offers = offersRes.data?.queryOffersContents;

  useEffect(() => {
    getFavoritesFx();
  }, []);

  const favoriteOffersItems = useMemo(() => {
    if (!offers || !favoriteOffers) return [];
    return offers.filter((item: any) => favoriteOffers.includes(item.data.couponCode.iv));
  }, [offers, favoriteOffers]);

  return (
    <div className={css.MyFavoriteOffers}>
      {!favoriteOffersItems.length && isLoadingFavorites && offersRes.isValidating && (
        <div className={css.loadOrEmpty}>
          <Loader />
        </div>
      )}

      {!favoriteOffersItems.length && !isLoadingFavorites && !offersRes.isValidating && (
        <div className={css.loadOrEmpty}>
          <div className={css.empty}>{t('the-list-is-empty')}</div>
        </div>
      )}

      <div className={css.grid}>
        {favoriteOffersItems.map((item: any) => (
          <OfferItem key={item.id} {...item} />
        ))}
      </div>
    </div>
  );
}
