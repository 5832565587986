import {createEvent, createEffect, createStore, attach, guard, forward} from 'effector';
import axios from 'axios';

import {ApiResponse} from '../app_types';
import {$token, getMemberFx} from '../auth/auth';
import {showToastFx} from '../components/components';

const activateBooklet5 = createEffect(async (token: string) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/booklet-5', {token});
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  return true;
});

export const activateBooklet5Fx = attach({
  effect: activateBooklet5,
  source: $token,
});

const activateBooklet10 = createEffect(async (token: string) => {
  const {data, errorMessage}: ApiResponse = await axios.post('/members/booklet-10', {token});
  if (errorMessage) return showToastFx({text: errorMessage, status: 'error'});
  return true;
});

export const activateBooklet10Fx = attach({
  effect: activateBooklet10,
  source: $token,
});

/**
 * Conditional actions
 * ========================================================
 */
guard({
  source: activateBooklet5.doneData,
  filter: (value) => value === true,
  target: getMemberFx,
});

guard({
  source: activateBooklet10.doneData,
  filter: (value) => value === true,
  target: getMemberFx,
});
